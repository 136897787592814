// CSS laden
import '../scss/main.scss';

// Bootstrap 5
import '@popperjs/core/dist/umd/popper.min.js';
import * as bootstrap from 'bootstrap';

(() => {

  const fraukeLogoContainer = document.getElementById('logo-container');
  const fraukeLogo = fraukeLogoContainer.querySelector('img');
  fraukeLogo.id = 'logo';
  fraukeLogo.alt = 'Frauke Mayer Logo';

  document.querySelectorAll('figure.image_container').forEach((figure) => {
    const image = figure.querySelector('img');
    console.log(image);
    image.classList.add('img-fluid');
  });

})();
